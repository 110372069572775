import axios from "axios";

const get = route => {
  return axios.get(process.env.VUE_APP_ROOT_API + route, {
    withCredentials: true
  });
};

const post = (route, data) => {
  return axios.post(process.env.VUE_APP_ROOT_API + route, data, {
    withCredentials: true
  });
};

const patch = (route, data) => {
  return axios.patch(process.env.VUE_APP_ROOT_API + route, data, {
    withCredentials: true
  });
};

const del = route => {
  return axios.delete(process.env.VUE_APP_ROOT_API + route, {
    withCredentials: true
  });
};

export { get, post, patch, del };
