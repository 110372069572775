import Vue from "vue";
import Router from "vue-router";
const ProjectSelector = () => import("@/views/ProjectSelector");
const SelectedProject = () => import("@/views/SelectedProject");
const Contacts = () => import("@/views/Project/Contacts");
const Documents = () => import("@/views/Project/Documents");
const Systems = () => import("@/views/Project/Systems");
const Login = () => import("@/views/Login");

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/",
      name: "projects",
      component: ProjectSelector
    },
    {
      path: "/:slug",
      name: "project",
      component: SelectedProject,
      children: [
        {
          path: "contacts",
          component: Contacts
        },
        {
          path: "documents",
          component: Documents
        },
        {
          path: "systems",
          component: Systems
        }
      ]
    }
  ]
});
