<template>
  <footer>
    <div class="flex-align">
      <span>&copy; {{ (new Date).getFullYear() }} Barrier Management Program</span>
      <a href="https://www.stifirestop.com" class="sti-link m-2" target="_blank">
        <img
          src="https://assets.stifirestop.com/images/logo/sti-horz-2020.svg"
          class="logo"
          alt="STI Firestop"
        >
      </a>
    </div>
    <div class="flex-align mb-2">
      <a class="mr-1" href="https://www.stifirestop.com/legal/privacy-policy/">Privacy Policy</a>
      |
      <a
        class="mx-1"
        href="https://www.stifirestop.com/legal/electronic-file-policy"
      >Electronic File Policy</a>
      |
      <a
        class="ml-1"
        href="https://www.stifirestop.com/legal/terms-and-conditions/"
      >Terms and Conditions</a>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  border-top: 1px solid #dad9d9;
  background-color: #fff;
  padding: 5px;
}
img {
  width: 150px;
  height: auto;
}
.flex-align {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: wrap row;
  font-size: 14px;
}
</style>
