<template>
  <div>
    <Header :loadChildren="loadChildren" :myId="myId" :loggedIn="loggedIn" />
    <div id="app">
      <div class="logos">
        <router-link to="/">
          <img
            src="./assets/images/bmp-logo.png"
            class="logo"
            alt="STI Firestop"
          />
        </router-link>
        <img
          v-if="currentProjId !== -1 && showLogo"
          :src="logoRoute"
          alt="company-logo"
          @error="showLogo = false"
        />
      </div>
      <i v-if="!loadChildren" class="fa fa-spinner fa-spin spinner"></i>
      <transition
        name="new-page"
        mode="out-in"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut animation-duration"
      >
        <router-view
          v-if="loadChildren"
          :loggedIn="loggedIn"
          @updateProjId="updateProjId"
          :projects="projects"
          :myId="myId"
        />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import { get } from "@/utils/api";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      loggedIn: false,
      myId: {},
      projects: [],
      currentProjId: -1,
      loadChildren: false,
      showLogo: true,
    };
  },
  mounted() {
    get("user")
      .then(({ data }) => {
        this.myId = data;
        this.loggedIn = true;
        if (!this.projects[0]) {
          get("barrier-management/project-list?user_id=" + this.myId.id).then(
            ({ data }) => {
              this.projects = data;
              this.loadChildren = true;
            }
          );
        }
      })
      .catch(() => {
        this.$router.push("login");
        this.loadChildren = true;
      });
  },
  methods: {
    updateProjects(updatedProjects) {
      this.projects = updatedProjects;
    },
    updateProjId(newProjId) {
      this.currentProjId = newProjId;
    },
  },
  computed: {
    logoRoute() {
      return (
        process.env.VUE_APP_ROOT_API +
        "barrier-management/project/" +
        this.currentProjId +
        "/logo"
      );
    },
  },
};
</script>

<style lang="scss">
#app {
  min-height: calc(100vh - 146px);
  padding: 30px 50px;
}
.texture {
  background: url("./assets/images/concrete_seamless90.jpg") repeat center
    center fixed;
}
.logos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.logos img {
  height: 80px;
}
.animation-duration {
  animation-duration: 0.2s;
}
.spinner {
  font-size: 50px;
  color: #0063a1;
  position: fixed;
  margin: 20vh 0 0 43vw;
}
@media only screen and (max-width: 700px) {
  #app {
    padding: 30px 2px 80px 2px;
  }
  .logos {
    justify-content: center;
    img {
      margin: 10px 20px;
    }
  }
}
</style>
