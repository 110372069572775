import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCookies from "vue-cookies";
import BootstrapVue from "bootstrap-vue";
import dotenv from "dotenv";
import VueGtm from '@gtm-support/vue2-gtm';

dotenv.config();

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-swatches/dist/vue-swatches.min.css";

const isProd = process.env.NODE_ENV === "production";

Vue.use(VueGtm, {
  id: 'GTM-TQ8FG6P',
  // queryParams: {
  //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x',
  // },
  defer: false,
  compatibility: false,
  enabled: isProd, 
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});

Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
