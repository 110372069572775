<template>
  <header>
    <b-button
      :class="loadChildren ? '' : 'disabled'"
      @click="logInOut"
      variant="success"
    >Log {{inOrOut}}</b-button>
    <a class="username" :href="accessLink">{{fullName}}</a>
    <form method="POST" id="log-out" hidden :action="link"></form>
  </header>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["loggedIn", "myId", "loadChildren"],
  methods: {
    logInOut() {
      if (!this.loadChildren) return;
      if (this.inOrOut === "in") {
        window.location = this.link;
      } else {
        document.getElementById("log-out").submit();
      }
    }
  },
  computed: {
    fullName() {
      return this.loggedIn
        ? this.myId.first_name + " " + this.myId.last_name
        : "";
    },
    inOrOut() {
      return this.loggedIn ? "out" : "in";
    },
    link() {
      return `${process.env.VUE_APP_ROOT_API}log${this.inOrOut}?${
        this.inOrOut === "in" ? "intended" : "redirect"
      }=${process.env.VUE_APP_BMP_URL}`;
    },
    accessLink() {
      const sitePrefix = process.env.VUE_APP_ROOT_API.split("api")[0];
      return sitePrefix + "access.stifirestop.com/";
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  background-color: #018fd5;
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: white;
}
.username {
  color: white;
  padding-right: 10px;
}
</style>

